import sendFetch from '@/functions/sendFetch';

const MUTATION_PROFILES_WITH_SL_TP = 'profilesWithSlTp';
const MUTATION_PROFILES = 'profiles';

export default {
  namespaced: true,
  state: () => ({
    profiles: {},
    profilesWithSlTp: {},
  }),
  mutations: {
    [MUTATION_PROFILES](state, data) {
      state.profiles = data;
    },
    [MUTATION_PROFILES_WITH_SL_TP](state, data) {
      state.profilesWithSlTp = data;
    },
  },
  actions: {
    init({ commit }) {
      sendFetch({
        url: `/api/profile?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          const profiles = {};
          const profilesWithSlTp = {};

          data.forEach((item) => {
            profiles[item.id] = item;
            profilesWithSlTp[item.id] = item.has_sl_tp;
          });

          commit(MUTATION_PROFILES, profiles);
          commit(MUTATION_PROFILES_WITH_SL_TP, profilesWithSlTp);
        },
      });
    },
  },
  getters: {
    profiles(state) {
      return state.profiles;
    },
    profilesWithSlTp(state) {
      return state.profilesWithSlTp;
    },
  },
};
