import sendFetch from '@/functions/sendFetch';

const MUTATION_ASSETS = 'assets';

export default {
  namespaced: true,
  state: () => ({
    assets: [],
  }),
  mutations: {
    [MUTATION_ASSETS](state, data) {
      state.assets = data;
    },
  },
  actions: {
    init({ commit }) {
      sendFetch({
        url: `/api/assets?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_ASSETS, data);
        },
      });
    },
  },
  getters: {
    assets(state) {
      return state.assets;
    },
  },
};
