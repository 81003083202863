import sendFetch from '@/functions/sendFetch';

const MUTATION_SET = 'setData';

export default {
  namespaced: true,
  state: () => ({
    data: [],
  }),
  mutations: {
    [MUTATION_SET](state, data) {
      state.data = data;
    },
  },
  actions: {
    init({ commit }) {
      sendFetch({
        url: `/api/roles?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_SET, data);
        },
      });
    },
  },
  getters: {
    all(state) {
      return state.data;
    },
  },
};
