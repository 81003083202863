import sendFetch from '@/functions/sendFetch';

const MUTATION_ADVISERS = 'advisers';
const MUTATION_PROFILES = 'profiles';

export default {
  namespaced: true,
  state: () => ({
    advisers: [],
    profiles: [],
  }),
  mutations: {
    [MUTATION_ADVISERS](state, data) {
      state.advisers = data;
    },
    [MUTATION_PROFILES](state, data) {
      state.profiles = data;
    },
  },
  actions: {
    init({ commit }) {
      sendFetch({
        url: `/api/advisers?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_ADVISERS, data);
        },
      });
    },
    initCurrentProfiles({ commit }) {
      sendFetch({
        url: `/api/advisers/current-profiles?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_PROFILES, data);
        },
      });
    },
  },
  getters: {
    advisers(state) {
      return state.advisers;
    },
    profiles(state) {
      return state.profiles;
    },
  },
};
