import { createApp } from 'vue';
import { defaultConfig, plugin } from '@formkit/vue';
import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf';
import VueScrollTo from 'vue-scrollto';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueTheMask from 'vue-the-mask';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App';
import router from './router';
import store from './store';

AOS.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 200,
  delay: 0,
  duration: 400,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});

const optionsVueScrollto = {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -104,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
};

createApp(App)
  .use(VueTheMask)
  .use(Vue3SimpleHtml2pdf)
  .use(plugin, defaultConfig)
  .use(store)
  .use(router)
  .use(VueScrollTo, optionsVueScrollto)
  .use(VueAxios, axios)
  .mount('#app');
