<template>
  <router-view/>
  <NoticeWrapper></NoticeWrapper>
  <CookiesPopup></CookiesPopup>
  <DefaultModal></DefaultModal>
</template>
<script>

import NoticeWrapper from '@/components/helpers/notice/NoticeWrapper';
import CookiesPopup from '@/components/СookiesPopup';
import DefaultModal from '@/systems/modals/DefaultModal';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'App',
  components: {
    DefaultModal,
    NoticeWrapper,
    CookiesPopup,
  },
  mounted() {
    this.$store.dispatch('checkUser');
    this.$store.commit('addTradingView');
  },
};

</script>
<style>
@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: 'Circe';
  src: local('Circe Regular'), local('Circe-Regular'),
  url('@/assets/fonts/Circe-Regular.woff2') format('woff2'),
  url('@/assets/fonts/Circe-Regular.woff') format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: local('Circe Bold'), local('Circe-Bold'),
  url('@/assets/fonts/Circe-Bold.woff2') format('woff2'),
  url('@/assets/fonts/Circe-Bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: local('Circe ExtraBold'), local('Circe-ExtraBold'),
  url('@/assets/fonts/Circe-ExtraBold.woff2') format('woff2'),
  url('@/assets/fonts/Circe-ExtraBold.woff') format('woff');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --bs-font-sans-serif: 'Circe', sans-serif;
  --bs-body-font-family: 'Circe', sans-serif;
  --bs-font-monospace: 'Circe', sans-serif;
  --bs-btn-font-family: 'Circe', sans-serif;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.btn-primary {
  --bs-btn-bg: #575585;
  --bs-btn-border-color: #575585;
  --bs-btn-hover-bg: #3C3E59;
  --bs-btn-hover-border-color: #3C3E59;
  --bs-btn-active-bg: #2e315c;
  --bs-btn-active-border-color: #2e315c;
  --bs-btn-disabled-bg: #737287;
  --bs-btn-disabled-border-color: #737287;
}

.btn-secondary {
  --bs-btn-color: #2B2B2B;
  --bs-btn-bg: #F4F5F4;
  --bs-btn-border-color: #F4F5F4;
  --bs-btn-hover-color: #2B2B2B80;
  --bs-btn-hover-bg: #F4F5F450;
  --bs-btn-hover-border-color: #F4F5F450;
  --bs-btn-active-color: #2B2B2B80;
  --bs-btn-active-bg: var(--bs-gray-100);
  --bs-btn-active-border-color: var(--bs-gray-200);
  --bs-btn-disabled-color: #2B2B2B80;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

label {
  display: block;
}

dl, ol, ul {
  margin-bottom: 0;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

ol, ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

picture {
  display: flex;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  margin: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

p {
  margin-bottom: 0;
}

.full-height {
  display: flex;
  background: radial-gradient(100% 100% at 50% 0%, #F4F5F4 0%, #FFFFFF 0.01%, #F4F5F4 100%);
  min-height: 100vh;
}

.bg-linear {
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
}

.text-inline {
  white-space: nowrap;
}

.text-inline span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-inline .select .multiselect__tags {
  width: calc(100% - 24px);
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.notice {
  padding-bottom: 0.75em;
  position: fixed;
  top: 1.5em;
  left: 1.5em;
  width: 25em;
  max-width: calc(100% - 3em);
  transition: transform 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 1000000;
}

.notice__box {
  animation: flyIn 0.3s ease-out;
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.75em;
  box-shadow: 0 0.5em 1em hsla(223, 10%, 10%, 0.1);
  height: 5em;
  transition: background-color 0.15s, color 0.15s;
}

.notice__btns {
  box-shadow: -1px 0 0 hsla(223, 10%, 10%, 0.15);
  flex-direction: column;
  flex-shrink: 0;
  min-width: 6em;
  height: 100%;
  transition: box-shadow 0.15s;
}

.notice__btn {
  background-color: transparent;
  box-shadow: 0 0 0 hsla(223, 10%, 10%, 0.5) inset;
  font-size: 0.7em;
  line-height: 1;
  font-weight: 500;
  height: 100%;
  padding: 0 0.5rem;
  transition: background-color 0.15s, color 0.15s;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
}

.notice__btn:first-of-type {
  border-radius: 0 0.75rem 0 0;
}

.notice__btn + .notice__btn {
  box-shadow: 0 -1px 0 hsla(223, 10%, 10%, 0.15);
  font-weight: 400;
}

.notice__btn:last-of-type {
  border-radius: 0 0 0.75rem 0;
}

.notice__btn:only-child {
  border-radius: 0 0.75rem 0.75rem 0;
}

.notice__btn-text {
  display: inline-block;
  pointer-events: none;
}

.notice__content {
  padding: 0.375em 1em;
  width: 100%;
  height: 100%;
}

.notice__box,
.notice__content {
  align-items: center;
}

.notice__box,
.notice__content,
.notice__btns {
  display: flex;
}

.notice__icon {
  flex-shrink: 0;
  margin-right: 0.75em;
  width: 2em;
  height: 2em;
}

.notice__icon-picture {
  width: 100%;
  height: auto;
  display: block;
}

.notice__icon-img {
  display: block;
  max-width: 100%;
}

.notice__text {
  line-height: 1.333;
}

.notice__text-title {
  font-size: 0.95em;
  font-weight: bold;
}

.notice__text-subtitle {
  font-size: 0.8em;
  opacity: 0.75;
}

.notice--out .notice__box {
  animation: flyOut .3s ease-out forwards;
}

@keyframes flyIn {
  from {
    transform: translateX(calc(-100% - 1.5em));
  }

  to {
    transform: translateX(0);
  }
}

@keyframes flyOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 1.5em));
  }
}

body {
  min-width: 360px;
  font-size: 12px;
}

body.lock {
  overflow: hidden;
}

#app {
  font-family: var(--bs-body-font-family);
  color: #2B2B2B;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  max-width: 1460px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.section-title {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.section-descr {
  font-size: 24px;
  line-height: 28px;
}

.section-descr_weight {
  font-weight: 700;
}

.card-descr {
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 16px;
}

.card-descr_weight {
  font-weight: 700;
}

.wrapper {
  background: radial-gradient(100% 100% at 50% 0%, #F4F5F4 0%, #FFFFFF 0.01%, #F4F5F4 100%);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-wrapper {
  display: flex;
  flex-grow: 1;
}

.main-cabinet {
  display: flex;
}

.main__container {
  flex-grow: 1;
  padding: 0 20px;
}

.cabinet-title {
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.cabinet-sub-title {
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

.verification__container {
  border: 1px solid #B5C6F2;
  box-shadow: 0px 4px 64px rgba(61, 63, 91, 0.05);
  border-radius: 10px;
  padding: 32px;
  background-color: #fff;
}

.radio-label {
  display: flex;
  cursor: pointer;
}

.radio-label-text {
  font-size: 16px;
  line-height: 24px;
}

.radio-decorator {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: rgba(209, 209, 209, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
}

.radio-decorator__check {
  width: 12px;
  height: 12px;
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
  backdrop-filter: blur(1.5px);
  border-radius: 50%;
  opacity: 0;
  transition: opacity .2s ease;
}

.radio-input:checked + .radio-decorator .radio-decorator__check {
  opacity: 1;
}

.advisor {
  display: flex;
  align-items: center;
}

.advisor__img {
  min-width: 80px;
  height: 80px;
  width: 80px;
  margin-right: 16px;
}

.advisor__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 8px;
}

.advisor__descr {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8px;
  color: rgba(43, 43, 43, .5);
}

.advisor__more {
  font-size: 12px;
  line-height: 18px;
  color: rgba(43, 43, 43, .5);
  text-decoration-line: underline;
}

.container-cabinet {
  padding: 32px 32px 135px 32px;
  background-color: #fff;
  box-shadow: 0px 4px 64px rgba(61, 63, 91, 0.05);
  border-radius: 10px;
}

.input-main {
  padding: 12px 16px;
  background-color: #F4F5F4;
  border-radius: 3px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: rgba(43, 43, 43, .5);
}

.filter {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 0 10px;
  height: 24px;
  border-radius: 3px;
}

.filter.active {
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
}

.filter:last-child {
  margin-right: 0;
}

.filter__icon {
  margin-right: 10px;
}

.filter__text {
  font-size: 14px;
  color: rgba(43, 43, 43, .5);
}

.filter__text_color {
  color: rgba(43, 43, 43, 1);
}

.filter.active .filter__text {
  font-weight: 700;
  color: #fff;
}

.block-hide-btn {
  min-width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F5F4;
}

.block-hide-btn__img {
  transform: rotate(180deg);
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-label {
  font-size: 14px;
  line-height: 14px;
  color: #2B2B2B;
}

.checkbox-decorator {
  width: 24px;
  height: 24px;
  background-color: #F9F9F9;
  backdrop-filter: blur(1.5px);
  border-radius: 3px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease;
}

.checkbox-input:checked + .checkbox-decorator {
  background-color: #0D762A;
}

.checkbox-decorator-icon {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease, visibility .2s ease;
}

.checkbox-input:checked + .checkbox-decorator .checkbox-decorator-icon {
  opacity: 1;
  visibility: visible;
}

.checkbox-option {
  margin-bottom: 16px;
}

.checkbox-option:last-child {
  margin-bottom: 0;
}

.select.multiselect {
  display: flex;
  align-items: center;
  width: unset;
  height: 48px;
  padding: 0 8px;
}

.select.multiselect.select_main {
  background-color: #F4F5F4;
}

.select .multiselect__tags {
  padding: 0;
  height: 48px;
  min-height: 0;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
}

.select .multiselect__placeholder {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.select .multiselect__select {
  position: initial;
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L12 15L17 10' stroke='%232B2B2B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  opacity: .5;
  margin-right: 4px;
}

.select .multiselect__select:before {
  display: none;
}

.select .multiselect__content-wrapper {
  top: 100%;
  bottom: auto;
  border-radius: 0 0 3px 3px;
  left: 0;
}

.select.multiselect--above .multiselect__content-wrapper {
  bottom: auto;
  border: none;
  border-radius: 0 0 3px 3px;
}

.select .multiselect__input,
.select .multiselect__single {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 1440px) {
  .section-title {
    font-size: 38px;
    line-height: 40px;
  }

  .card-descr {
    font-size: 22px;
    line-height: 26px;
  }

  .section-descr {
    font-size: 20px;
    line-height: 22px;
  }
}

@media (max-width: 1400px) {
  .main-cabinet {
    display: block;
    margin-bottom: 50px;
  }

  .verification__container {
    padding: 32px 16px;
  }

  .container-cabinet {
    padding: 32px 16px;
  }
}

@media (max-width: 1024px) {
  .main-cabinet {
    padding-top: 104px;
  }

  .wrapper {
    padding-top: 0;
  }

  .main__container {
    padding: 0;
  }
}

@media (max-width: 991px) {
  main {
    flex: 1;
  }
}
</style>
