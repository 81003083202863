<template>
  <div :class="{'notice--out':remove}" :style="{transform: 'translateY('+index*100+'%)'}" class="notice">
    <div class="notice__box">
      <div class="notice__content" @click.prevent="close">
        <div class="notice__icon">
          <picture class="notice__icon-picture">
            <img v-if="status==='success'" alt="Notice success" class="notice__icon-img"
                 src="@/assets/notification/success.svg">
            <img v-else-if="status==='warn'" alt="Notice warn" class="notice__icon-img"
                 src="@/assets/notification/warn.svg">
            <img v-else-if="status==='error'" alt="Notice error" class="notice__icon-img"
                 src="@/assets/notification/error.svg">
            <img v-else alt="Notice message" class="notice__icon-img" src="@/assets/notification/message.svg">
          </picture>
        </div>
        <div class="notice__text">
          <div class="notice__text-title">{{ title }}</div>
          <div class="notice__text-subtitle">{{ description }}</div>
        </div>
      </div>
      <div v-if="btnFirst || btnSecond" class="notice__btns">
        <button v-if="btnFirst" :type="btnFirst?.type ?? 'button'" class="notice__btn"
                @click="btnFirst.callback ? btnFirst.callback(this) : close()">
          <span class="notice__btn-text">{{ btnFirst?.text ?? '' }}</span>
        </button>
        <button v-if="btnSecond" :type="btnSecond?.type ?? 'button'" class="notice__btn"
                @click="btnSecond.callback ? btnSecond.callback(this) : close()">
          <span class="notice__btn-text">{{ btnSecond?.text ?? '' }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'description', 'status', 'index', 'timeout', 'btnFirst', 'btnSecond'],
  name: 'NoticeBlock',
  data() {
    return {
      remove: false,
    };
  },
  methods: {
    close() {
      this.remove = true;
      this.$emit('remove');
    },
  },
  mounted() {
    if (this.timeout !== 0) {
      setTimeout(() => {
        this.close();
      }, this.timeout || 6000);
    }
  },
};
</script>

<style scoped>

</style>
