import setActivityBlock from '@/functions/setActivityBlock';

export default function sendFetch(
  {
    url, before, success, error, options, block, final, store, removeHeaders = false,
  },
) {
  if (before) before();
  if (block) setActivityBlock(block, 'disable');

  let toFinalyResponse = null;
  let initFetch;

  if (!removeHeaders) {
    initFetch = {
      headers: {
        'Content-type': 'application/json',
      },
      ...options,
    };
  } else {
    initFetch = {
      ...options,
    };
  }
  return fetch(url, initFetch)
    .then(async (response) => {
      toFinalyResponse = response;

      if (!response.ok) {
        if (error) {
          try {
            error(await response.json());
          } catch (e) {
            console.log(e);
            error({ detail: 'Ошибка сервера! Сообщите об этом администратору!' });
          }
        }
        return false;
      }
      try {
        const resp = await response.json();
        if (success) success(resp);
      } catch (e) {
        if (success) success(response);
      }

      return true;
    })
    .catch(async (response) => {
      toFinalyResponse = response;

      try {
        const resp = await response.json();
        if (error) error(resp);
      } catch (e) {
        console.log(e);
        if (error) error(response);
      }
    })
    .finally(() => {
      if (block) setActivityBlock(block);

      if (toFinalyResponse.status === 401 || toFinalyResponse.status === 403) {
        if (store) {
          store.dispatch('toLogin');
        }
      }

      if (final) final(toFinalyResponse);
    });
}
