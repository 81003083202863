import { createStore } from 'vuex';
import sendFetch from '@/functions/sendFetch';
import setActivityBlock from '@/functions/setActivityBlock';

// eslint-disable-next-line import/no-cycle
import router from '@/router';
import modalWindow from '@/store/modules/modalWindow';
import profiles from './profiles';
import assets from './assets';
import investments from './investments';
import advisers from './advisers';
import durations from './durations';
import users from './users';
import rolesModule from './roles';

let userMetaResolver = () => {
};

export default createStore({
  modules: {
    profiles,
    assets,
    investments,
    advisers,
    durations,
    users,
    roles: rolesModule,
    modals: modalWindow,
  },
  state: {
    currentUser: {},
    notices: {},
    tradingViewScript: null,
    settingsSuccessGet: new Promise((resolve) => {
      userMetaResolver = resolve;
    }),
  },
  mutations: {
    addTradingView(state) {
      if (!state.tradingViewScript) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://s3.tradingview.com/tv.js');
        script.setAttribute('defer', 'true');
        script.setAttribute('type', 'text/javascript');
        script.id = 'tradingView';
        document.head.appendChild(script);
        state.tradingViewScript = script;
      }
    },
    addNotice(state, notice) {
      let date = new Date();
      let id = `${date.getTime()}${date.getMilliseconds()}`;

      while (state.notices[id]) {
        date = new Date();
        id = `${date.getTime()}${date.getMilliseconds()}`;
      }

      state.notices[id] = {
        ...notice,
        id,
      };
    },
    deleteNotice(state, noticeIndex) {
      setTimeout(() => {
        delete state.notices[noticeIndex];
      }, 400);
    },
    refreshUser(state, json) {
      state.currentUser = json;
    },
    updateUserData(state, {
      id,
      logged,
      name,
      second_name,
      last_name,
      birthday,
      blocked,
      contract_photo,
      education,
      email,
      phone,
      photo,
      profile_id,
      registry_number,
      roles,
      passport_create,
      passport_from,
      passport_from_code,
      passport_number,
      passport_photo,
      passport_series,
      createdAt,
      updatedAt,
      verified,
      profile,
      verified_documents,
      qualified,
      citizenship,
      city_registration,
      reference_link,
    }) {
      state.currentUser = {
        id: id || state.currentUser.id,
        logged: logged || state.currentUser.logged,
        name: name || state.currentUser.name,
        second_name: second_name || state.currentUser.second_name,
        last_name: last_name || state.currentUser.last_name,
        birthday: birthday || state.currentUser.birthday,
        blocked: blocked || state.currentUser.blocked,
        contract_photo: contract_photo || state.currentUser.contract_photo,
        education: education || state.currentUser.education,
        email: email || state.currentUser.email,
        phone: phone || state.currentUser.phone,
        photo: photo || state.currentUser.photo,
        profile_id: profile_id || state.currentUser.profile_id,
        registry_number: registry_number || state.currentUser.registry_number,
        roles: roles || state.currentUser.roles,
        passport_create: passport_create || state.currentUser.passport_create,
        passport_from: passport_from || state.currentUser.passport_from,
        passport_from_code: passport_from_code || state.currentUser.passport_from_code,
        passport_number: passport_number || state.currentUser.passport_number,
        passport_photo: passport_photo || state.currentUser.passport_photo,
        passport_series: passport_series || state.currentUser.passport_series,
        createdAt: createdAt || state.currentUser.createdAt,
        updatedAt: updatedAt || state.currentUser.updatedAt,
        verified: verified || state.currentUser.verified,
        profile: profile || state.currentUser.profile,
        verified_documents: verified_documents || state.currentUser.verified_documents,
        qualified: qualified ?? state.currentUser.qualified,
        citizenship: citizenship ?? state.currentUser.citizenship,
        city_registration: city_registration ?? state.currentUser.city_registration,
        reference_link: reference_link ?? state.currentUser.reference_link,
      };
      localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
    },
    userLogin(state, { phone, password, callback }) {
      this.commit('setSettingSuccessGet');

      const mainBlockAuth = document.querySelector('.auth');

      sendFetch({
        url: '/api/auth/login',
        block: mainBlockAuth,
        options: {
          body: JSON.stringify({ phone, password }),
          method: 'POST',
        },
        success: async (resp) => {
          this.commit('addNotice', {
            title: 'Успешная авторизация',
            status: 'success',
          });
          const response = resp.user;

          this.dispatch('convertToStore', {
            ...response,
            roles: resp.user_roles,
          });

          userMetaResolver();

          if (callback) {
            callback({ response });
          }
        },
        error: () => {
          userMetaResolver();

          this.commit('addNotice', {
            title: 'Ошибка авторизации',
            description: 'Проверьте правильность ввода данных',
            status: 'error',
          });
        },
      });
    },
    userRegister(state, { phone, callback }) {
      const mainBlockAuth = document.querySelector('.auth');
      sendFetch({
        url: '/api/auth/register',
        options: {
          method: 'POST',
          body: JSON.stringify({
            phone,
          }),
        },
        block: mainBlockAuth,
        success: async () => {
          setActivityBlock(mainBlockAuth, 'disable');
          this.commit('addNotice', {
            title: 'Успешная регистрация!',
            description: 'Пароль выслан на номер, который вы указали!',
            timeout: 6000,
            status: 'success',
          });

          if (callback) {
            callback();
          }
        },
        error: async () => {
          this.commit('addNotice', {
            title: 'Ошибка во время регистрации!',
            status: 'error',
          });
        },
      });
    },
    setSettingSuccessGet(state) {
      state.settingsSuccessGet = new Promise((resolve) => {
        userMetaResolver = resolve;
      });
    },
  },
  actions: {
    toLogin(state) {
      localStorage.removeItem('currentUser');
      state.dispatch('checkUser');
      router.push({ name: 'authorization' });
      this.commit('addNotice', {
        title: 'Пожалуйста авторизируйтесь',
        status: 'error',
      });
    },
    userLogout(state, { callback, withOutFetch = false } = {}) {
      if (!withOutFetch ?? false) {
        sendFetch({
          url: '/api/auth/logout',
          options: {
            method: 'POST',
          },
          success: () => {
            localStorage.removeItem('currentUser');
            state.dispatch('checkUser');
            callback();
          },
          error: () => {
            this.commit('addNotice', {
              title: 'Error exit',
              description: 'Please, send message to support',
              status: 'error',
            });
          },
        });
      } else {
        localStorage.removeItem('currentUser');
        state.dispatch('checkUser', { withOutFetch });

        if (callback) {
          callback();
        }
      }
    },
    convertToStore({ commit }, response) {
      commit('updateUserData', {
        id: response.id,
        logged: true,
        name: response.name,
        second_name: response.second_name,
        last_name: response.last_name,
        birthday: response.birthday,
        blocked: response.blocked,
        contract_photo: response.contract_photo,
        education: response.education,
        email: response.email,
        phone: response.phone,
        photo: response.photo,
        profile_id: response.profile_id,
        registry_number: response.registry_number,
        roles: response.roles,
        passport_create: response.passport_create,
        passport_from: response.passport_from,
        passport_from_code: response.passport_from_code,
        passport_number: response.passport_number,
        passport_photo: response.passport_photo,
        passport_series: response.passport_series,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
        verified: response.verified,
        profile: response.profile,
        verified_documents: response.verified_documents,
        qualified: response.qualified,
        citizenship: response.citizenship,
        city_registration: response.city_registration,
        reference_link: response.reference_link,
      });
    },
    checkUser(state, { withOutFetch = false } = {}) {
      if (!withOutFetch ?? false) {
        state.commit('setSettingSuccessGet');
        state.dispatch('getUserMetaData');
      }
      const storage = JSON.parse(localStorage.getItem('currentUser'));
      state.commit('refreshUser', storage ?? {
        id: 0,
        logged: false,
        name: null,
        second_name: null,
        last_name: null,
        birthday: null,
        blocked: false,
        contract_photo: null,
        education: null,
        email: null,
        phone: null,
        photo: null,
        profile_id: 0,
        registry_number: null,
        roles: null,
        passport_create: null,
        passport_from: null,
        passport_from_code: null,
        passport_number: null,
        passport_photo: null,
        passport_series: null,
        createdAt: null,
        updatedAt: null,
        verified: null,
        qualified: null,
        citizenship: null,
        city_registration: null,
        reference_link: null,
      });
    },
    getUserMetaData({ commit, dispatch, getters }) {
      sendFetch({
        url: '/api/settings',
        options: {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        },
        success: async (response) => {
          dispatch('convertToStore', response);

          userMetaResolver();
          commit('setSettingSuccessGet');
        },
        final: (resp) => {
          if (resp.status === 401 && getters.currentUser.logged) {
            dispatch('userLogout', {
              withOutFetch: true,
              callback: () => {
                window.location.reload();
              },
            });
          }
        },
      });
    },
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    currentNotices(state) {
      return state.notices;
    },
    currentUserRoles(state) {
      if (state.currentUser.roles && state.currentUser.roles.length > 0) {
        return state.currentUser.roles.map((role) => role.name);
      }
      return [];
    },
    settingsGet(state) {
      return state.settingsSuccessGet;
    },
  },
});
