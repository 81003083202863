import sendFetch from '@/functions/sendFetch';

const MUTATION_INVESTMENTS = 'investments';

export default {
  namespaced: true,
  state: () => ({
    investments: [],
  }),
  mutations: {
    [MUTATION_INVESTMENTS](state, value) {
      state.investments = value;
    },
  },
  actions: {
    init({ commit }) {
      sendFetch({
        url: `/api/investment?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_INVESTMENTS, data);
        },
      });
    },
    create({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        sendFetch({
          url: '/api/investment',
          options: {
            body: JSON.stringify(data),
            method: 'POST',
          },
          success: () => {
            dispatch('init');
            resolve();
          },
          error: () => {
            reject();
          },
        });
      });
    },
    update({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        sendFetch({
          url: `/api/investment/${data.id || ''}`,
          options: {
            body: JSON.stringify(data),
            method: data.id ? 'PUT' : 'POST',
          },
          success: () => {
            dispatch('init');
            resolve();
          },
          error: () => {
            reject();
          },
        });
      });
    },
    delete({ dispatch }, id) {
      sendFetch({
        url: `/api/investment/${id}`,
        options: {
          method: 'DELETE',
        },
        success: () => {
          dispatch('init');
        },
      });
    },
  },
  getters: {
    investments(state) {
      return state.investments;
    },
  },
};
