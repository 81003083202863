<template>
  <template v-for="(notice, index) in allNotice" :key="notice.id">
    <NoticeBlock
      :btnFirst="notice.btnFirst" :btnSecond="notice.btnSecond" :description="notice.description"
      :index="index" :status="notice.status"
      :timeout="notice.timeout" :title="notice.title"
      @remove="removeNotice(notice.id)"></NoticeBlock>
  </template>
</template>

<script>
import NoticeBlock from '@/components/helpers/notice/NoticeBlock';

export default {
  name: 'NoticeWrapper',
  components: { NoticeBlock },
  computed: {
    allNotice() {
      return Object.values(this.$store.getters.currentNotices);
    },
  },
  methods: {
    removeNotice(id) {
      this.$store.commit('deleteNotice', id);
    },
  },
};
</script>

<style scoped>

</style>
