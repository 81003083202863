import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/LandingView'),
  },
  {
    path: '/auth/sign-in/',
    name: 'authorization',
    component: () => import('@/views/auth/AuthorizationView'),
    meta: {
      ifAuth: () => {
      },
    },
  },
  {
    path: '/auth/sign-up/',
    name: 'registration',
    component: () => import('@/views/auth/RegistrationView'),
    meta: {
      ifAuth: () => {
      },
    },
  },
  {
    path: '/auth/recovery/',
    name: 'recovery',
    component: () => import('@/views/auth/RecoveryView'),
    meta: {
      ifAuth: () => {
      },
    },
  },
  {
    path: '/u/settings/',
    name: 'settings-user',
    component: () => import('@/views/cabinet/UserSettingsView'),
    meta: {
      needAuth: true,
      role: 'USER',
    },
  },
  {
    path: '/documents/',
    name: 'documents',
    component: () => import('@/views/DocumentsPage'),
  },
  {
    path: '/documents/info/',
    name: 'document',
    component: () => import('@/views/documents/GeneralInfo'),
  },
  {
    path: '/u/:current_route?',
    name: 'cabinet-user',
    component: () => import('@/views/cabinet/UserMain'),
    meta: {
      needAuth: true,
      role: 'USER',
    },
  },
  {
    path: '/a/',
    name: 'cabinet-advisor',
    component: () => import('@/views/cabinet/RecomendationAdvisorView'),
    meta: {
      needAuth: true,
      role: 'ADVISOR',
    },
  },
  {
    path: '/a/statistics/',
    name: 'statistics-advisor',
    component: () => import('@/views/cabinet/StatisticsAdvisorView'),
    meta: {
      needAuth: true,
      role: 'ADVISOR',
    },
  },
  {
    path: '/a/settings/',
    name: 'settings-advisor',
    component: async () => import('@/views/cabinet/AdvisorSettingsView'),
    meta: {
      needAuth: true,
      role: 'ADVISOR',
    },
  },
  {
    path: '/admin/',
    name: 'cabinet-admin',
    component: () => import('@/views/admin/AdminPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/appeals/',
    name: 'appeals',
    component: () => import('@/views/AppealsView'),
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/admin/assets/',
    name: 'assets-admin',
    component: () => import('@/views/admin/AssetsPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/admin/promo_codes/',
    name: 'promo_codes-admin',
    component: () => import('@/views/admin/PromoCodesPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/admin/subscription',
    name: 'subscription-admin',
    component: () => import('@/views/admin/SubscriptionsPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/admin/testing',
    name: 'testing-admin',
    component: () => import('@/views/admin/TestingPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/admin/profiles',
    name: 'profiles-admin',
    component: () => import('@/views/admin/ProfilesPage'),
    meta: {
      needAuth: true,
      role: 'ADMIN',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'pageNotFound',
    component: async () => import('@/views/PageNotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const previewParam = 'previewLocate';
  document.querySelector('body').classList.remove('lock');

  /* eslint-disable */
  delete from[previewParam];
  to[previewParam] = from;
  /* eslint-enable */

  if (to.meta.needAuth) {
    if (!store.getters.currentUser.logged && to.name !== 'authorization') {
      return next({ name: 'authorization' });
    }
  }

  if (store.getters.currentUser.logged && (to.name === 'authorization' || to.name === 'registration' || to.name === 'recovery')) {
    return next({ name: 'landing' });
  }

  if (to.meta.role) {
    store.getters.settingsGet.then(async () => {
      if ((!store.getters.currentUser.logged || !store.getters.currentUserRoles.includes(to.meta.role)) && to.name !== 'authorization') {
        store.commit('addNotice', {
          title: 'Ошибка доступа',
          description: 'Недостаточно прав для просмотра данной страницы',
          status: 'warn',
        });
        await store.dispatch('userLogout');
        next({ name: 'authorization' });
      }
    });
  }

  if (to.meta.ifAuth && store.getters.currentUser.logged) {
    return next(to.meta.ifAuth());
  }

  return next();
});

export default router;
