import sendFetch from '@/functions/sendFetch';

const MUTATION_PROFILE = 'setProfile';
const MUTATION_SUBSCRIPTION = 'setSubscription';

export default {
  namespaced: true,
  state: () => ({
    profile: {},
    subscription: {},
  }),
  mutations: {
    [MUTATION_PROFILE](state, data) {
      state.profile = data;
    },
    [MUTATION_SUBSCRIPTION](state, data) {
      state.subscription = data;
    },
  },
  actions: {
    initProfile({ commit }) {
      sendFetch({
        url: `/api/users/current-profile?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_PROFILE, data);
        },
      });
    },
    initSubscription({ commit }) {
      sendFetch({
        url: `/api/users/current-subscription?${new Date().getTime()}`,
        options: {
          method: 'GET',
        },
        success: (data) => {
          commit(MUTATION_SUBSCRIPTION, data);
        },
      });
    },
    allUserData(_, id) {
      return new Promise((resolve) => {
        sendFetch({
          url: `/api/users/all-data/${id}?${new Date().getTime()}`,
          options: {
            method: 'GET',
          },
          success: (data) => {
            resolve(data);
          },
        });
      });
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    subscription(state) {
      return state.subscription;
    },
  },
};
