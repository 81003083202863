<template>
  <Transition>
    <div v-show="!cookiesAccepted" class="cookies">
      <div class="cookies__container">
        <img src="@/assets/img/cookies.svg" alt="" class="cookies__img">
        <div class="cookies__content">
          <p class="cookies__title">
            Мы используем cookie для вашего удобства, вы не против?
          </p>
          <p class="cookies__text">
            Мы используем cookie-файлы для ускорения вашей работы с платформой, чтобы сделать Вашу жизнь комфортнее путем
            анализа событий на нашем веб-сайте.
            Продолжая просмотр страниц нашего сайта, вы принимаете условия его использования. Подробности вы можете узнать
            в нашей политике конфиденциальности.
          </p>
        </div>
        <button @click="hideCookies" class="cookies__btn">
          Согласен
        </button>
      </div>
    </div>
  </Transition>
</template>

<script>
const LOCAL_STORAGE_KEY_COOKIES = 'cookiesAccepted';

export default {
  data() {
    return {
      cookiesAccepted: !!localStorage.getItem(LOCAL_STORAGE_KEY_COOKIES),
    };
  },
  methods: {
    hideCookies() {
      localStorage.setItem(LOCAL_STORAGE_KEY_COOKIES, '1');
      this.cookiesAccepted = true;
    },
  },
};
</script>

<style scoped>
.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #EAF0FF;
  padding: 32px 40px;
  z-index: 1000000;
}

.cookies__container {
  display: flex;
  align-items: flex-start;
}

.cookies__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 8px;
}

.cookies__img {
  margin-right: 24px;
}

.cookies__content {
  margin-right: 24px;
}

.cookies__btn {
  background: linear-gradient(180deg, #575585 0%, #3C3E59 100%);
  border-radius: 3px;
  padding: 14px 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  min-width: 280px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media (max-width: 991px) {
  .cookies__container {
    display: block;
  }

  .cookies__img {
    margin-right: 0;
    margin-bottom: 28px;
  }

  .cookies__content {
    margin-right: 0;
    margin-bottom: 28px;
  }
}

@media (max-width: 768px) {
  .cookies__img {
    display: none;
  }

  .cookies__title {
    font-size: 20px;
    line-height: 20px;
  }

  .cookies__text {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
