<template>
  <Transition name="fade">
    <div class="modal fade" :class="{ show }" v-show="show" style="display: block" tabindex="-1" ref="modal">
      <div v-show="show" class="modal-backdrop fade" :class="{ show }" @click="closeModal"></div>
      <div class="modal-dialog position-relative" style="z-index: 1056">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fs-4">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="closeModal"></button>
          </div>
          <template v-if="type === 'form'">
            <div class="modal-body">
              <component
                v-if="show"
                :is="FormComponent"
                id="modal-form"
                :data="data"
                @submit.prevent="submit"
                novalidate
                mimeType="text/javascript"
                fileExtension=".mjs"
              ></component>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">
                Закрыть
              </button>
              <button type="submit" form="modal-form" class="btn btn-primary">Сохранить</button>
            </div>
          </template>
          <template v-else-if="type === 'confirm'">
            <div class="modal-body">
              <div class="mb-3 text-center" v-html="data.description"></div>
              <form @submit.prevent="submit" class="d-flex align-items-center justify-content-between">
                <button type="button" class="btn btn-outline-secondary" @click="closeModal">
                  Отменить
                </button>
                <button type="submit" class="btn btn-outline-success">
                  Подтвердить
                </button>
              </form>
            </div>
          </template>
          <template v-else>
            <component
              v-if="show"
              :is="FormComponent"
              id="modal-form"
              :data="data"
              @submit.prevent="submit"
              novalidate
              mimeType="text/javascript"
              fileExtension=".mjs"
            ></component>
          </template>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';
import getFormData from 'get-form-data';
import { merge } from 'lodash';

export default {
  name: 'DefaultModal',
  methods: {
    parseString(path, acc = {}) {
      if (path.length === 0) return acc;

      const key = path.pop();

      return this.parseString(path, {
        [key]: acc,
      });
    },
    submit(event) {
      const form = event.target;
      const validator = form.checkValidity();
      const data = getFormData(form);

      let resultData = {};

      Object.keys(data).forEach((key) => {
        const keys = key.split('.');

        const parsing = this.parseString(keys, data[key]);
        resultData = merge(resultData, parsing);
      });

      if (validator) {
        this.callback(resultData, form.closest('.modal-content'));
      } else {
        let i = 0;

        const keys = Object.keys(data);
        keys.forEach((name) => {
          if (!form[name].length && !form[name].checkValidity() && form[name].dataset.label) {
            setTimeout(() => {
              this.$store.commit('notifications/addNotice', {
                title: `${form[name].dataset.label} - поле не корректно`,
                status: 'warn',
              });

              form[name].classList.add('is-invalid');
              setTimeout(() => {
                form[name].classList.remove('is-invalid');
              }, 5000);
            }, i++ * 50);
          }
        });
      }
    },
    closeModal() {
      this.$store.commit('modals/closeModal');
    },
  },
  computed: {
    FormComponent() {
      return defineAsyncComponent(this.loadedComponent);
    },
    ...mapGetters({
      show: 'modals/getShow',
      title: 'modals/getTitle',
      loadedComponent: 'modals/getComponent',
      callback: 'modals/getCallback',
      data: 'modals/getData',
      type: 'modals/getType',
    }),
  },
  watch: {
    show: {
      handler(closeScroll) {
        if (closeScroll) {
          document.body.classList.add('close-scroll');

          const mod = this.$refs.modal;

          setTimeout(() => {
            const input = mod.querySelector('input');
            if (input) {
              input.focus();
            }
          }, 400);
        } else {
          document.body.classList.remove('close-scroll');
        }
      },
    },
  },

};
</script>

<style scoped>

</style>
