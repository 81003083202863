export default {
  name: 'modals',
  namespaced: true,
  state: () => ({
    title: null,
    componentName: null,
    show: false,
    data: null,
    type: null,
    callback: () => {
    },
    availableComponents: {
      EditProfile: () => import('@/components/forms/EditProfile'),
      EditQuestion: () => import('@/components/forms/EditQuestion'),
      EditAnswer: () => import('@/components/forms/EditAnswer'),
      EditSubscription: () => import('@/components/forms/EditSubscription'),
      EditAsset: () => import('@/components/forms/EditAssets'),
      EditPromoCodes: () => import('@/components/forms/EditPromoCodes'),
      NotFound: () => import('@/systems/error/NotFound'),
    },
  }),
  mutations: {
    loadModal(state, {
      title,
      component,
      data = null,
      type,
      callback = () => {
      },
    }) {
      state.componentName = component;
      state.title = title;
      state.data = data;
      state.callback = callback;
      state.type = type ?? 'form';
      state.show = true;
    },
    closeModal(state) {
      state.show = false;
      state.title = null;
      state.componentName = null;
      state.data = null;
      state.type = null;
      state.callback = () => {
      };
    },
  },
  getters: {
    getTitle(state) {
      return state.title;
    },
    getComponent(state) {
      let result = null;

      if (Object.keys(state.availableComponents).indexOf(state.componentName) !== -1) {
        result = state.availableComponents[state.componentName];
      } else {
        result = state.availableComponents.NotFound;
      }

      return result;
    },
    getShow(state) {
      return state.show;
    },
    getData(state) {
      return state.data;
    },
    getCallback(state) {
      return state.callback;
    },
    getType(state) {
      return state.type;
    },
  },
};
